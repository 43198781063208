import type { Subscription } from 'rxjs'
import type { InitConfig, LegacyConfig } from './types/config'
import type { PushArguments, PushFunction } from './types/network'

import type { OnLoadArguments, SetupArguments } from './types/setup'
import type { SortableId } from './util/id'

import { engine, recorder } from './modules'
import { initEngine } from './modules/engine/engine'

import { events, kinesis, setup, verify } from './network'
import { fetchJWT, fetchMetrics, fetchPing, isTokenValid } from './network/auth'
import { handlePageSwitching } from './pageSwitching'
import { DOMSerializer } from './util/domSerializer'
import { merge, runAtIndex } from './util/functional'
import { getPageId, getSessionId, getVisitorID } from './util/id'
import { logger } from './util/logging'
import { makeSessionEndingObservable } from './util/rxjs'
import { cleanOldIds, getLastVisit, saveLastVisit } from './util/storage'
// this is needed to make vite bundling work with a global
import './polyfills'

const NETWORK = { setup, events, kinesis, verify } as const // TODO: need to make this partial so that the rest of the client can respond to it

// To run a module, import it and include it in this list
const MODULES = { engine, recorder } as const

// Tuples of source, destination
// const MIDDLEWARE = [util
// [MODULES.recorder, 'recorder$', NETWORK.recorder], [MODULES.engine, 'codelessEvents$', NETWORK.events]] as const

// Utility function to expose keys to global so that typescript doesn't complain each time
function expose(key: string, value: any) {
  // @ts-expect-error - this is fine
  window[key] = value
}

function fireSetup(
  args: SetupArguments,
) {
  return Promise.all(runAtIndex(Object.values(MODULES), 'onSetupEvent', args))
    .then(p => merge(...p))
}

function fireOnSessionLoad(
  args: OnLoadArguments,
) {
  return Promise.all(runAtIndex(Object.values(MODULES), 'onSessionLoad', args)).then(p => merge(...p))
}

interface NtagGlobalState {
  startTimestamp: number
  lastVisit: Date | undefined
  sessionId: string
  pageId: SortableId
  pageUrl: string
  visitorId: string
}

function getGlobalState(window: Window, config: Readonly<InitConfig>): NtagGlobalState {
  const startTimestamp = Date.now()
  const lastVisit = getLastVisit(config.key)
  const sessionId = getSessionId(config.key, lastVisit)
  const pageId = getPageId(startTimestamp, sessionId)
  const pageUrl = window.location.href
  const visitorId = getVisitorID()

  return {
    startTimestamp,
    lastVisit,
    sessionId,
    pageId,
    pageUrl,
    visitorId,
  }
}

// `key` is the value that is passed to the ntag
// `token` is the JWT token that is returned from the server
export async function init(
  window: Window,
  config: Readonly<InitConfig | LegacyConfig>,
) {
  if ((config as LegacyConfig).token && !(config as InitConfig).key) {
    console.warn('You are using a legacy config. Please update to the new config format.')
    config = {
      key: (config as LegacyConfig).token,
      LOG_ENDPOINT: (config as LegacyConfig).LOG_ENDPOINT,
      disableWatch: (config as LegacyConfig).disableWatch,
      local: (config as LegacyConfig).local,
    } as InitConfig
  }
  config = config as InitConfig

  if (!config.key)
    throw new Error('You must provide a key to init()')

  const { key } = config

  const domSerializer = new DOMSerializer()
  const globalState = getGlobalState(window, config)
  const subscriptions: Record<string, Subscription | undefined> = {}

  // const startTimestamp = Date.now()
  // const lastVisit = getLastVisit(config.key)
  // const sessionId = getSessionId(config.key, lastVisit)
  // const pageId = getPageId(startTimestamp, sessionId)
  // const pageUrl = location.href
  // const visitorId = getVisitorID()

  expose('_ntag_key', config.key) // expose token to integrations

  // Don't await them until needed for optimization
  const tokenPromise = fetchJWT(config.key)
  const pingPromise = fetchPing()

  const withTokenGuard = <T>(fn: (token: string) => T) => tokenPromise.then((token) => {
    if (!isTokenValid(token)) {
      logger.error('Token is expired, not pushing')
      return
    }
    return fn(token)
  })

  const handleNewPage = (globalState: NtagGlobalState) => {
    // Expose some globals to the window so integrations and debuggers can see them
    expose('_ntag_session_id', globalState.sessionId)
    expose('_ntag_page_id', globalState.pageId)

    const metricsPromise = fetchMetrics({ ...globalState, key }).then(({ engines, eventListeners }) => ({
      engines,
      eventListeners,
      activeEngines: initEngine(globalState.sessionId, engines), // i hate this with every bone in my body but it's prettier than passing stupid globals around
    }))

    // Prevent localStorage from getting too full
    cleanOldIds(config.key, globalState.sessionId)
    saveLastVisit(config.key)

    const handleLoad = () => {
      const setupArgs: SetupArguments = {
        ...globalState,
        window,
        document: window.document,
        config,
        domSerializer,
        // startTimestamp: globalState.startTimestamp,
        pingPromise,
        metricsPromise,
        tokenPromise,
        key,
        // sessionId: globalState.sessionId,
        // pageId: globalState.pageId,
      }

      fireSetup(setupArgs).then((setup) => {
        withTokenGuard((token) => {
          const { sessionId, pageId } = setupArgs
          NETWORK.setup?.push({ key, token, sessionId, pageId }, setup)
          NETWORK.verify?.push({ key, token })
          if (config.local)
            expose('_nlytics_setup', setup)
        })
      })

      const sessionEnding$ = makeSessionEndingObservable(window)

      const basePushArgs = {
        ...globalState,
        key,
      }

      fireOnSessionLoad({
        ...setupArgs,
        sessionEnding$,
      }).then((observables) => {
        // Closure addiction. Sue me.
        const pusherWithToken = <Payload>(pusher: PushFunction<any, Payload>) =>
          (payload: Payload) => withTokenGuard((token) => {
            return pusher({ ...basePushArgs, token }, payload)
          })

        // Optional chaining ensures that if the modules or networking are turned off, nothing will be pushed
        // TODO: perhaps clean this up a bit because it's very WET
        // for (const [m, observableKey, n] of MIDDLEWARE) {
        //   if (n) {
        //     subscriptions[observableKey] ??= observables[observableKey]?.subscribe(pusherWithToken(n.push))
        //   }
        // }
        // Comment reason: implenetation incomplete
        // Trying to make this idempotent lol
        if (NETWORK.kinesis)
          subscriptions.kinesis$ ??= observables.recorder$?.subscribe(pusherWithToken(NETWORK.kinesis.push))
        if (NETWORK.events)
          subscriptions.codelessEvents$ ??= observables.codelessEvents$?.subscribe(pusherWithToken(NETWORK.events.push))
      })
    }

    if (document.readyState === 'complete') {
      handleLoad()
    }
    else {
      window.addEventListener('load', handleLoad)
    }
  }

  handleNewPage(globalState)

  const basePushArgs = {
    ...globalState,
    key,
  }

  withTokenGuard((token) => {
    const pushArgs: PushArguments = {
      ...basePushArgs,
      token,
    }

    // If we have any existing events, push them
    // This causes duplicate events if the user navigates away when server has received the request but client has not received the response
    // We assume all flushable APIs are therefore idempotent
    // stability: need to check backend for all these
    // NETWORK.setup.queue.flush(pushArgs)
    // NETWORK.events.queue.flush(pushArgs)
    // NETWORK.recorder.queue.flush(pushArgs)
    Object.values(NETWORK).filter(n => 'queue' in n).forEach(n => n.queue?.flush(pushArgs))
  })

  handlePageSwitching(window)

  window.addEventListener('locationchange', async () => {
    // Cleanup old subscriptions
    subscriptions.codelessEvents$?.unsubscribe() // TODO: make this more extensible. There should be a way to configure unsubscription globally

    // Reset IDs
    const globalState = getGlobalState(window, config)

    // Call the new page
    handleNewPage(globalState)
  })

  logger.debug('💅 Initialized')
}
